export enum MessageEnum {
  contact = 1,
  demo = 2
}

export interface FormMessageInterface {
  message: MessageEnum,
  fields: {[key: string]: string|number|boolean},
  meta: {
    reference: string,
    campaign?: string,
  }
}
