import {FormMessageInterface, MessageEnum} from "../types/form-message.interface";

export class FormMessageHelper {
  static getBodyRequest(message: MessageEnum, fields: {[key: string]: string|number|boolean}): FormMessageInterface {
    const params = new URLSearchParams(window.location.pathname);
    const campaign = params.get('campaign_id') || params.get('gclid');

    const body: FormMessageInterface = {
      message,
      fields,
      meta: {
        reference: window.location.href,
      }
    }

    if (campaign) {
      body.meta.campaign = campaign;
    }

    return body;
  }
}
