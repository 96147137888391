import * as React from "react"
import type {PageProps} from "gatsby"
import "@fontsource/bai-jamjuree";
import "@fontsource/inter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@mytracko/mytracko-theme/themes/mytracko-theme.scss';
import 'maplibre-gl/dist/maplibre-gl.css';
import {graphql, HeadProps} from "gatsby";

import Layout from "../components/Layout";
import Hero, {HeroProps} from "../components/homepage/hero/Hero";
import Features from "../components/homepage/features/Features";
import Applications, {UsageProps} from "../components/homepage/Applications";
import HowItWorks, {HowItWorksProps} from "../components/homepage/HowItWorks";
import Application, {ApplicationProps} from "../components/homepage/Application";
import Device, {DeviceProps} from "../components/homepage/Device";
import {SubscriptionProps} from "../components/homepage/subscription";
import SpecialOffer from "../components/templates/special-offer/SpecialOffer";
import Help from "../components/templates/help/Help";
import Distributor, {DistributorProps} from "../components/homepage/distributor/Distributor";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import {ISpecialOfferSection} from "../components/templates/special-offer/special-offer.interface";
import {SiteMetadata} from "../types/site-metadata.type";
import Demo from "../components/homepage/Demo";
import StructuredData from '../assets/structured-data';
import SpecialOfferOwner from "../components/homepage/SpecialOfferOwner";
import ImportantInfoPopup, {ImportantInfoPopupProps} from "../components/homepage/important-info-popup/ImportantInfoPopup";

type HomepageProps = {
  strapiHomepage: {
    hero: HeroProps,
    usage: UsageProps,
    howItWorks: HowItWorksProps,
    application: ApplicationProps,
    subscription: SubscriptionProps,
    device: DeviceProps
    distributor: DistributorProps,
    help: IHelpSection,
    specialOffer: ISpecialOfferSection,
    specialOfferOwner: ISpecialOfferSection,
    siteMetadata: SiteMetadata
    importantInfoPopup: ImportantInfoPopupProps
  }
}

const IndexPage = ({data: {strapiHomepage}}: PageProps<HomepageProps>) => {
  const {
    help,
    specialOffer,
    hero,
    usage,
    howItWorks,
    application,
    device,
    subscription,
    distributor,
    specialOfferOwner,
    importantInfoPopup,
  } = strapiHomepage;

  return (
    <Layout>
      <ImportantInfoPopup importantInfoPopup={importantInfoPopup} />
      <Hero hero={hero}/>
      <Features />
      <Applications applications={usage}/>
      <HowItWorks howItWorks={howItWorks}/>
      <Application application={application}/>
      <Device device={device}/>
      <SpecialOffer specialOffer={specialOffer} />
      <SpecialOfferOwner specialOfferOwner={specialOfferOwner} />
      <Demo/>
      <Help
        helpSection={help}
        className={'flex flex-col justify-center text-center items-center bg-white px-5 pt-20 md:pt-36 pb-16'}
      />
      <Distributor distributor={distributor}/>
    </Layout>
  )
}

export default IndexPage

export const Head = (props: HeadProps<HomepageProps>) => (
  <>
    <title>{props.data.strapiHomepage.siteMetadata.title}</title>
    <meta name="description" content={props.data.strapiHomepage.siteMetadata.description}/>
    <meta name="robots" content="index, follow"/>
    <meta name="theme-color" content="#24B25B"/>
    { StructuredData() }
  </>
)

export const query = graphql`
  query {
    strapiHomepage {
      distributor {
        title
        number
        email
        description
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      subscription {
        title
        description
        advantageTitle
        advantages {
          text
        }
        package {
          name
          price
          url
        }
      }
      device {
        title
        partnerTitle
        subtitle
        description
        properties {
          text
         }
        buttons {
          url
          label
        }
        images {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
      application {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, breakpoints: [640, 768, 1024, 1280])
            }
          }
        }
      }
      howItWorks {
        title
        readMoreLink
        steps {
          text
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, breakpoints: [640, 768, 1024, 1280], quality: 100)
            }
          }
        }
      }
      usage {
        title
        description {
          data {
            description
          }
        }
        cardLabel
        cardUrl
        cards {
          label
          url
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
            alternativeText
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
      hero {
        title {
          text
        }
        description
        button
        background {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
            }
          }
        }
        flyer {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(height:690, layout: FIXED, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
      siteMetadata {
        title
        description
        crumbLabel
      }
      specialOffer {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
      help {
        title
        description
        number
        email
      }
      specialOfferOwner {
        title {
          data {
            title
          }
        }
        description {
          data {
            description
          }
        }
        button {
          label
          url
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
      importantInfoPopup {
        enabled
        popupId
        incentiveText {
          data {
            incentiveText
          }
        }
        incentiveButtonText
        title {
          data {
            title
          }
        }
        content {
          data {
            content
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        button {
          label
          url
        }

      }
    }
  }
`
