import React from "react";
import {LogoProps} from "./Logo";

const LogoWhite = (props: LogoProps<any>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2000 383.19" className={props.class}>
      <title>Logo MYTRACKO</title>
      <rect x="10" y="81.03" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="120.57" y="81.03" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="302.16" y="10" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="10" y="191.59" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="120.57" y="191.59" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="231.13" y="191.59" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="10" y="302.16" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="120.57" y="302.16" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <rect x="231.13" y="302.16" style={{"fill": "#FFFFFF"}} width="71.03" height="71.03"/>
      <g>
        <g>
          <path style={{"fill": "#FFFFFF"}} d="M482.88,373.19V206.02h25.77l69.42,82.65l69.43-82.65h25.77v167.17h-18.81V227.38l-76.39,90.78l-76.39-90.78
			v145.81H482.88z"/>
          <path style={{"fill": "#FFFFFF"}} d="M766.14,373.19v-62.92l-76.85-104.25h21.59l64.78,81.73l64.08-81.73h22.06l-76.85,104.25v62.92H766.14z"/>
          <path style={{"fill": "#FFFFFF"}} d="M951.43,373.19V224.83h-74.3v-18.81h167.18v18.81h-74.07v148.36H951.43z"/>
          <path style={{"fill": "#FFFFFF"}} d="M1061.48,373.19V206.25h139.31c7.74,0,14.32,2.75,19.74,8.24c5.42,5.5,8.13,12.04,8.13,19.62v48.99
			c0,7.58-2.71,14.13-8.13,19.62c-5.42,5.5-12,8.24-19.74,8.24h-25.54l52.48,61.99v0.23h-24.84l-52.24-62.22l-70.36-0.23v62.46
			H1061.48z M1089.35,291.93h111.44c2.48,0,4.56-0.85,6.27-2.55c1.7-1.7,2.55-3.79,2.55-6.27v-48.99c0-2.47-0.85-4.61-2.55-6.39
			c-1.71-1.78-3.8-2.67-6.27-2.67h-111.44c-2.48,0-4.61,0.89-6.39,2.67c-1.78,1.78-2.67,3.91-2.67,6.39v48.99
			c0,2.48,0.89,4.56,2.67,6.27C1084.73,291.07,1086.87,291.93,1089.35,291.93z"/>
          <path style={{"fill": "#FFFFFF"}} d="M1253.73,373.19V233.88c0-7.74,2.7-14.31,8.13-19.73c5.42-5.42,11.99-8.13,19.73-8.13h111.45
			c7.74,0,14.32,2.71,19.73,8.13c5.42,5.42,8.13,11.99,8.13,19.73v139.31h-18.81v-61.06h-129.56v61.06H1253.73z M1272.54,293.32
			h129.56v-59.44c0-2.47-0.89-4.6-2.67-6.38c-1.78-1.78-3.91-2.67-6.38-2.67h-111.45c-2.48,0-4.61,0.89-6.38,2.67
			c-1.78,1.78-2.67,3.91-2.67,6.38V293.32z"/>
          <path style={{"fill": "#FFFFFF"}} d="M1475.24,373.19c-7.74,0-14.32-2.7-19.73-8.13c-5.42-5.42-8.13-11.99-8.13-19.73V233.88
			c0-7.74,2.71-14.31,8.13-19.73c5.42-5.42,12-8.13,19.73-8.13h138.85v18.81h-138.85c-2.48,0-4.61,0.89-6.38,2.67
			c-1.78,1.78-2.67,3.91-2.67,6.38v111.45c0,2.48,0.89,4.61,2.67,6.38c1.78,1.78,3.91,2.67,6.38,2.67h138.85v18.81H1475.24z"/>
          <path style={{"fill": "#FFFFFF"}} d="M1638.46,373.19V206.02h19.04v74.07h53.87l62.22-74.07h24.15v0.23l-69.89,83.36l70.12,83.35v0.23h-24.38
			l-62.22-74.06h-53.87v74.06H1638.46z"/>
          <path style={{"fill": "#FFFFFF"}} d="M1981.85,214.12c-5.41-5.41-11.95-8.08-19.68-8.08h-111.48c-7.73,0-14.34,2.67-19.75,8.08
			c-5.41,5.41-8.08,12.02-8.08,19.75v111.48c0,7.73,2.67,14.27,8.08,19.68c5.41,5.48,12.02,8.15,19.75,8.15h111.48
			c7.73,0,14.27-2.67,19.68-8.15c5.41-5.41,8.15-11.95,8.15-19.68V233.87C1990,226.14,1987.26,219.53,1981.85,214.12z
			 M1971.16,345.35c0,2.46-0.84,4.57-2.67,6.4c-1.76,1.76-3.87,2.67-6.33,2.67h-111.48c-2.46,0-4.64-0.91-6.4-2.67
			c-1.76-1.83-2.67-3.94-2.67-6.4V233.87c0-2.46,0.91-4.57,2.67-6.4c1.76-1.76,3.94-2.67,6.4-2.67h111.48
			c2.46,0,4.57,0.91,6.33,2.67c1.83,1.83,2.67,3.94,2.67,6.4V345.35z"/>
        </g>
      </g>
    </svg>
  )
}

export default LogoWhite;
