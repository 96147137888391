import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import CustomLink from "../shared/custom-link/CustomLink";
import {GatsbyImageCustomType} from "../../types/gatsby-image.type";

export type HowItWorksProps = {
  title: string,
  readMoreLink: string,
  steps: [{
    text: string
  }],
  image: GatsbyImageCustomType
}

const HowItWorks = ({howItWorks}: {howItWorks: HowItWorksProps}) => {
  return (
    <section className={'flex overflow-hidden flex-row bg-tertiary-40 sm:bg-white sm:px-10 py-3 sm:my-20 justify-center items-center h-[600px] lg:h-[700px] xl:h-[770px]'}>
      <div className={'hidden sm:flex items-center justify-center h-full'}>
        <GatsbyImage className={'h-full w-[400px] lg:w-[670px]'} alt={howItWorks.image.alternativeText} image={howItWorks.image.localFile.childImageSharp.gatsbyImageData} />
      </div>
      <div className={'flex flex-col shrink-1 bg-tertiary-40 rounded px-12 xl:px-28 pt-16 pb-20 w-full items-center text-center sm:items-baseline sm:text-start sm:max-w-[670px] h-full'}>
        <h2 className={'mb-12 break-words text-white font-head text-4xl sm:text-3xl lg:text-4xl xl:text-5xl'}>
          {howItWorks.title}
        </h2>
        {howItWorks.steps.map((step, index) => {
          return (
            <div key={index} className={'w-full sm:w-auto flex flex-row items-center mb-6 lg:mb-9 last-of-type:mb-8 lg:last-of-type:mb-16 sm:odd:ml-12'}>
              <div className={'w-16 h-16 lg:w-20 xl:w-24 lg:h-20 xl:h-24 shrink-0 rounded-full border-2 border-secondary-60 bg-transparent flex items-center justify-center mr-6'}>
                <p className={'text-2xl lg:text-3xl xl:text-4xl text-white font-head'}>{index + 1}</p>
              </div>
              <div className={'flex flex-col gap-1.5 text-start'}>
                <p className={'uppercase text-primary-60 text-xs lg:text-sm text-semibold'}>Krok NR {index + 1}</p>
                <p className={'font-head text-medium text-xl lg:text-2xl xl:text-3xl text-white'}>{step.text}</p>
              </div>
            </div>
          )
        })}
        <CustomLink isInternal className={'sm:ml-auto uppercase text-sm text-white border-b pb-[2px] hover:text-primary-60 hover:border-primary-60 transition'} to={howItWorks.readMoreLink}>czytaj więcej</CustomLink>
      </div>
    </section>
  )
}

export default HowItWorks;
