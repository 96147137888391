import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import CustomLink from "../../shared/custom-link/CustomLink";
import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import ContactInformation from '../../../assets/contact-information';
import './distributor.scss';
import AcceptancePrivacyPolicy from "../../shared/acceptance-privacy-policy/AcceptancePrivacyPolicy";

export type DistributorProps = {
  title: string,
  description: string,
  email: string,
  number: string,
  image: GatsbyImageCustomType,
}

const Distributor = ({distributor}: {distributor: DistributorProps}) => {
  return(
    <section className={'bg-tertiary-40 w-full relative'}>
      <div className={'hidden lg:flex absolute top-0 left-0 w-full h-20 bg-white z-0'} />
      <div className={'max-w-[1920px] ml-auto mr-auto overflow-hidden flex flex-col-reverse lg:flex-row px-5 lg:px-36 items-center'}>
        <GatsbyImage className={'lg:mr-32 z-10 w-[375px] lg:w-auto'} alt={distributor.image.alternativeText} image={distributor.image.localFile.childImageSharp.gatsbyImageData} />
        <div className={'flex flex-col mt-20 mb-9 lg:mb-0'}>
          <h2 className={'font-head text-4xl xl:text-5xl text-white font-medium max-w-sm mb-2.5'}>{distributor.title}</h2>
          <p className={'text-white text-base xl:text-lg mb-11'}>{distributor.description}</p>
          <CustomLink isInternal={false} className={'distributor--btn'} to={`tel:${ContactInformation.phoneCooperation}`}>
            <div className={'flex w-12 h-12 lg:w-16 lg:h-16 items-center justify-center border-t border-x border-secondary-60'}>
              <span className={'mtr-icon text-white !text-2xl lg:!text-3xl'}>call</span>
            </div>
            <p className={'text-xl xl:text-2xl text-white'}>{ContactInformation.phoneCooperation}</p>
          </CustomLink>
          <CustomLink isInternal={false} className={'distributor--btn'} to={`mailto:${ContactInformation.emailCooperation}`}>
            <div className={'flex w-12 h-12 lg:w-16 lg:h-16 items-center justify-center border border-secondary-60'}>
              <span className={'mtr-icon text-white !text-2xl lg:!text-3xl'}>mail</span>
            </div>
            <p className={'text-xl xl:text-2xl text-white hover:text-neutrals-80'}>{ContactInformation.emailCooperation}</p>
          </CustomLink>
          <AcceptancePrivacyPolicy classNameLink={'underline ml-1 hover:text-white transition-colors'} className={'text-neutrals-80 text-xs lg:text-sm mt-6'}/>
        </div>
      </div>
    </section>
  )
}

export default Distributor;
