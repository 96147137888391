import React, {useContext, useRef, useState} from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import LogoWhite from "../../shared/logo/LogoWhite";
import Modal, {ModalOptions, ModalRef, Position} from "../../shared/modal/modal";
import CookiesContext from "react-cookie/es6/CookiesContext";
import TextFormatter from "../../../utils/TextFormatter";
import './imporatantInfoPopup.css';

export interface ImportantInfoPopupProps {
  enabled: boolean,
  popupId: string,
  incentiveText: {
    data: {
      incentiveText: string
    }
  },
  incentiveButtonText: string,
  title: {
    data: {
      title: string
    }
  },
  content: {
    data: {
      content: string,
    }
  },
  image?: GatsbyImageCustomType,
  button: {
    url: string,
    label: string,
  },
}

const ImportantInfoPopup = ({importantInfoPopup}: {importantInfoPopup: ImportantInfoPopupProps}) => {
  const ref = useRef<ModalRef>(null);
  const cookies = useContext(CookiesContext);
  const popupShown = !!cookies.get(importantInfoPopup.popupId);

  const incentiveText = TextFormatter.concatOrphans(importantInfoPopup.incentiveText.data.incentiveText) || importantInfoPopup.incentiveText.data.incentiveText;
  const title = TextFormatter.concatOrphans(importantInfoPopup.title.data.title) || importantInfoPopup.title.data.title;
  const content = TextFormatter.concatOrphans(importantInfoPopup.content.data.content) || importantInfoPopup.content.data.content;

  const [enabled, setEnabled] = useState(importantInfoPopup.enabled)

  const popupOptions = {
    position: Position.middle,
    closeOnEsc: true,
    closeOnOverlay: true,
    initialVisible: importantInfoPopup.enabled && !popupShown,
  } as ModalOptions;

  const showPopup = () => {
    ref.current?.openModal();
  }

  const closePopup = () => {
    ref.current?.closeModal();
    markAsShown();
  }

  function markAsShown(): void {
    const cookieDate = new Date();
    cookieDate.setMonth(cookieDate.getMonth() + 1);
    cookies.set(importantInfoPopup.popupId, true, {expires: cookieDate});
  }

  if (!enabled) {
    return null;
  }

  return (
    <>
      <section className='bg-primary-60 w-full py-3.5 sm:py-5 px-5 2xl:px-10 flex justify-between sticky top-[68px] sm:top-[80px] lg:top-[78px] xl:top-[88px] z-30'>
        <div className='flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-x-4 '>
          <h3 className='text-white' dangerouslySetInnerHTML={{__html: incentiveText}}></h3>
          <button className='mtr-btn-small mtr-btn-[secondary]' onClick={showPopup}>
            <span className={'uppercase font-medium'}>{importantInfoPopup.incentiveButtonText}</span>
          </button>
        </div>

        <button className='mtr-icon !text-xl font-bold text-white' onClick={() => setEnabled(false)}>
          close
        </button>
      </section>

      <Modal ref={ref} options={popupOptions}>
        <div className='w-screen max-w-5xl max-h-[90vh] overflow-y-auto bg-primary-60 p-5 xl:p-10 w-full rounded-md'>
          <div className='flex flex-col sm:flex-row gap-x-5 lg:gap-x-10'>
            <div className='flex-1'>
              <LogoWhite class={"h-[24px] xl:h-[36px]"}></LogoWhite>
              {!!importantInfoPopup.image &&
                <GatsbyImage className={'h-[200px] rounded select-none sm:!hidden mt-6'}
                             alt={importantInfoPopup.image.alternativeText}
                             image={importantInfoPopup.image.localFile.childImageSharp.gatsbyImageData} />
              }

              <div className='overflow-y-auto mt-6 sm:mt-10 important-info-popup-content'>
                <h1 className='font-head font-bold text-2xl md:text-3xl text-white' dangerouslySetInnerHTML={{__html: title}}></h1>
                <div className='text-white md:text-lg mt-4' dangerouslySetInnerHTML={{__html: content}}></div>
              </div>

              <div className='grid grid-cols-2 sm:flex gap-x-2 md:gap-x-8 mt-6 sm:mt-10'>
                <a className='mtr-btn md:mtr-btn-large mtr-btn-[secondary]'
                   onClick={markAsShown}
                   href={importantInfoPopup.button.url}>
                  <span className={'uppercase font-medium'}>{importantInfoPopup.button.label}</span>
                </a>
                <button className='font-head text-white hover:bg-white/10 px-10 rounded-sm'
                        onClick={closePopup}>
                  Zamknij
                </button>
              </div>

            </div>
            {!!importantInfoPopup.image &&
              <div className='flex-1 hidden sm:block'>
                <GatsbyImage className={'w-full h-full rounded select-none rounded-lg'}
                             alt={importantInfoPopup.image.alternativeText}
                             image={importantInfoPopup.image.localFile.childImageSharp.gatsbyImageData} />
              </div>
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ImportantInfoPopup;
