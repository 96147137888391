import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import TextFormatter from "../../utils/TextFormatter";
import CustomLink from "../shared/custom-link/CustomLink";

export type UsageProps = {
  title: string,
  description: {
    data: {
      description: string,
    }
  },
  cardLabel: string,
  cardUrl: string,
  image: GatsbyImageCustomType,
  cards: [{
    label: string,
    url: string,
    image: GatsbyImageCustomType,
  }]
}

const Applications = ({applications: {cards, title, image, description, cardUrl, cardLabel}}: {applications: UsageProps}) => {
  return (
    <section className={'flex flex-col py-20 lg:pt-36 max-w-[1920px] ml-auto mr-auto'}>
      <div className={'flex flex-row gap-16 sm:mb-20 lg:mb-0 lg:h-[750px] xl:h-[983px] justify-center'}>
        <div className={'flex flex-col lg:ml-28 xl:ml-40 w-full w-3/4 lg:w-1/2'}>
          <h2 className={'text-4xl xl:text-5xl font-head mb-8 lg:w-[532px]'}>{title}</h2>
          <div className={'whitespace-pre-line text-neutrals-40 text-base xl:text-lg lg:w-[532px]'} dangerouslySetInnerHTML={{__html: TextFormatter.concatOrphans(description.data.description) || description.data.description}} />
        </div>
        <div className={'ml-auto z-0 hidden lg:block'}>
          <GatsbyImage className={'rounded-l'} alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData} />
        </div>
      </div>
      <div className={'grid p-10 sm:grid-cols-[repeat(2,_minmax(200px,_320px))] xl:grid-cols-[repeat(4,_minmax(200px,_320px))] gap-6 justify-center lg:mt-[-200px]'}>
        {cards.map((card, index) => {
          return (
            <CustomLink isInternal to={card.url} key={index} className={'flex flex-col gap-y-4 xl:odd:mt-10 hover:scale-[1.03] transition'} label={card.label}>
              <GatsbyImage className={'rounded h-[300px] sm:h-auto'} alt={card.image.alternativeText} image={card.image.localFile.childImageSharp.gatsbyImageData} />
              <div className={'flex flex-row justify-between items-center'}>
                <p className={'uppercase'}>{card.label}</p>
                <span className="mtr-icon !text-2xl text-secondary-60">
                  arrow_forward
                </span>
              </div>
            </CustomLink>
          )
        })}
        <div className={'flex flex-col justify-between rounded bg-primary-60 z-10 py-11 px-9 h-[300px] sm:h-auto'}>
          <p className={'uppercase font-head text-white text-xl md:text-2xl w-10'}>{cardLabel}</p>
          <CustomLink isInternal to={cardUrl} className="ml-auto w-12 md:w-16 h-12 md:h-16 bg-white rounded self-end flex items-center justify-center" label={cardLabel}>
            <span className="mtr-icon text-xl md:text-2xl">
              arrow_forward
            </span>
          </CustomLink>
        </div>
      </div>
    </section>
  )
}

export default Applications;
