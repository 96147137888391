import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import {SectionIdEnum} from "../../../types/section-id.enum";
import './features.css';
import CustomLink from "../../shared/custom-link/CustomLink";
import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";

type FeaturesProps = {
  strapiFeature: {
    title: string,
    functionalities: [{
      title: string,
      url: string,
      features: {
        text: string,
      }[],
      image: GatsbyImageCustomType
    }]
  }
}

const Features = () => {
  const {strapiFeature: {title, functionalities}} = useStaticQuery<FeaturesProps>(query);

  return (
    <section id={SectionIdEnum.FEATURES} className={'flex flex-col items-center bg-neutrals-90 py-24 px-5 sm:px-10'}>
      <h2 className={'font-head text-black text-4xl mb-8 md:mb-20'}>{title}</h2>
      <div className={'feature-container grid md:border bg-neutrals-60 border-neutrals-60 md:grid-cols-[repeat(2,_minmax(200px,_333px))] xl:grid-cols-[repeat(4,_minmax(200px,_333px))]'}>
        {functionalities.map((f, index) => {
          return (
            <div key={index} className={'flex justify-between flex-col py-10 sm:p-10 bg-neutrals-90 items-center xs:items-baseline'}>
              <div className="flex flex-col items-center xs:items-baseline">
                <GatsbyImage className={'mb-7 w-20'} alt={f.image.alternativeText} image={f.image.localFile.childImageSharp.gatsbyImageData} />
                <p className={'font-head text-4xl'}>{f.title}</p>
                <ul className={'text-neutrals-20 text-lg list-disc mt-4'}>
                  {f.features.map((feature, index) => {
                    return (
                      <li key={index} className={'font-head ml-5'}>{feature.text}</li>
                    )
                  })}
                </ul>
              </div>
              <div className="pt-10">
                <CustomLink isInternal to={f.url} className="mtr-btn-large mtr-btn-[secondary] h-12" label={'MYTRACKO ' + f.title}>
                  <span>SZCZEGÓŁY</span>
                  <span className="ml-2 mtr-icon text-white text-xl">
                    arrow_forward
                  </span>
                </CustomLink>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
};

export default Features;

export const query = graphql`
  query {
    strapiFeature {
      title
      functionalities {
        title
        url
        features {
          text
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
`
