import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import MobileAppLinks from "../shared/mobile-app-links/MobileAppLinks";
import TextFormatter from "../../utils/TextFormatter";

export type ApplicationProps = {
  title: string,
  description: {
    data: {
      description: string,
    }
  },
  image: GatsbyImageCustomType
}

const Application = ({application}: {application: ApplicationProps}) => {
  return (
    <section className={'flex flex-col lg:flex-row gap-x-20 xl:gap-x-36 px-10 py-20 lg:pl-36 lg:pr-20 lg:py-20 items-center justify-center'}>
      <div className={'flex flex-col sm:mb-24 w-full md:w-[440px]'}>
        <h2 className={'font-head text-neutrals-20 text-4xl xl:text-5xl mb-12'}>{application.title}</h2>
        <div className={'mb-11 whitespace-pre-line text-neutrals-40 text-base xl:text-lg'} dangerouslySetInnerHTML={{__html: TextFormatter.concatOrphans(application.description.data.description) || application.description.data.description}} />
        <MobileAppLinks className={'flex flex-col xs:flex-row justify-center items-center gap-3 self-center lg:self-baseline'}/>
      </div>
      <GatsbyImage className={'hidden xs:flex w-[300px] lg:w-[541px] h-[560px] lg:h-[750px] xl:h-[813px]'} alt={application.image.alternativeText} image={application.image.localFile.childImageSharp.gatsbyImageData} />
    </section>
  )
}

export default Application;
